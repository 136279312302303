import useTestContext from 'contexts/TestContext/hook'
import { FC, useEffect, useRef, useState } from 'react'
import { LogoText } from 'assets'
import { usePreviousTestsDB } from 'modules/previousTests/hook'
import TestSidebarContent from './content/Test'
import useShortsContext from 'contexts/ShortsContext/hook'
import ShortsSidebarContent from './content/Shorts'
import { Link } from 'react-router-dom'

const Sidebar: FC = () => {
  const { started } = useTestContext()
  const { picked: shorts } = useShortsContext()
  const previousDB = usePreviousTestsDB()
  const [previousCount, setPreviousCount] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  const onToggle = () => {
    ref.current?.classList.toggle('open')
  }

  const hide = () => {
    ref.current?.classList.remove('open')
  }

  useEffect(() => {
    previousDB.getAll().then((tests) => {
      setPreviousCount(tests.length)
    })
  }, [previousDB])

  return (
    <div ref={ref} className="sidebar z-[100000000000]">
      <div
        className="fixed backdrop w-full h-full z-10 bg-gradient-to-r hidden mobile:block mobileWidth:block from-[rgba(0,0,0,0.2)] to-[rgba(0,0,0,0.1)] transition-opacity duration-300"
        onClick={onToggle}
      />
      <div className="h-full content relative overflow-x-visible w-[15rem] shrink-0 bg-yellow-300 transition-transform duration-300 pt-8 pb-4 px-4 rounded-r-2xl shadow-2xl flex flex-col overflow-y-auto z-20 mobile:fixed mobileWidth:fixed mobile:left-4 mobileWidth:left-4">
        <Link to="/" onClick={hide}>
          <LogoText className="cursor-pointer" />
        </Link>
        {!started && !shorts ? (
          <>
            <Link className="button-primary mt-10" to="/tests" onClick={hide}>
              Testy
            </Link>
            <Link className="button-primary mt-4" to="/shorts" onClick={hide}>
              Shorts
            </Link>
            <Link data-tooltip-id="analyzy" data-tooltip-content={''} className="button-primary mt-4 relative flex items-center justify-center" to="/stats" onClick={hide}>
              Štatistiky ★ <b className="text-xs ml-1 mb-0.5">(BETA)</b>
            </Link>

            <div className="grow" />

            {!!previousCount && (
              <>
                <a href="https://donate.stripe.com/3cscQGc7f3NX5DW288" target="_blank" rel="noreferrer" className="button-primary bg-kura text-yellow-300 mt-4">
                  Podporiť
                </a>
                <Link className="button-primary mt-4" to="/previous" onClick={hide}>
                  Predchádzajúce testy
                </Link>
              </>
            )}

            {/*<Link
              className="button-primary mt-4"
              to="/tests" onClick={hide}
            >
              Preferencie
            </Link>*/}
            <Link className="button-primary mt-4" to="/about" onClick={hide}>
              O Projekte
            </Link>
          </>
        ) : shorts ? (
          <ShortsSidebarContent hide={hide} />
        ) : (
          <TestSidebarContent hide={hide} />
        )}
        <span className="text-kura text-xs font-medium text-center pt-4">So súhlasom NiVam</span>
      </div>
      <div onClick={onToggle} className="h-full catcher fixed w-4 left-0 top-0 z-30 hidden mobile:block mobileWidth:block" />
      <div onClick={onToggle} className="mobile:block mobileWidth:block hidden w-32 h-16 overflow-hidden absolute transition-all translate-x-20 hover:-translate-y-2 bottom-16 right-0 z-10">
        <div className="relative w-full h-full">
          <div className={['absolute w-1/2 h-full bg-kura left-0', shorts && 'mobileWidth:bg-yellow-300 mobile:bg-yellow-300'].asClass} />
          <div
            className={['absolute w-1/2 h-full bg-kura -translate-y-1/2 rotate-45 rounded-full left-full -translate-x-[150%]', shorts && 'mobileWidth:bg-yellow-300 mobile:bg-yellow-300'].asClass}
          />
          <div
            className={
              ['absolute w-1/2 h-full bg-kura translate-y-1/2 bottom-0 rounded-full rotate-45 left-full -translate-x-[150%]', shorts && 'mobileWidth:bg-yellow-300 mobile:bg-yellow-300'].asClass
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar

import { LogoText, LogoTextVertical } from 'assets'
import useTitle from 'hooks/useTitle'
import { FC } from 'react'
// import { PopupButton } from '@typeform/embed-react'

const AboutUsPage: FC = () => {
  useTitle('O nás', 'Vytvorené ako alternatíva ku status quo - papierové testy a manuálne opravovanie.')

  return (
    <div className="w-full h-full flex flex-col items-center justify-end px-8 select-none min-h-full h-max gap-5 mobile:pt-10 mobileWidth:pt-10 py-5">
      <LogoTextVertical className="w-16 min-w-16 opacity-0 shrink-0 mobile:hidden mobileWidth:hidden" />
      <div className="max-w-[600px] mobile:max-w-full mobileWidth:max-w-full flex flex-col items-center justify-center gap-2 min-h-max h-full grow">
        {/*<PopupButton id="zHa8DimU" style={{ fontSize: 20 }} className="button-primary text-2xl py-3 font-bold bg-kura text-yellow-300 px-12 w-max">
          Kontakt
  </PopupButton>*/}
        <h3 style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="text-center text-3xl font-semibold text-kura opacity-0 mb-10">
          Vytvorené ako alternatíva ku status quo - papierové testy a manuálne opravovanie.
        </h3>
        <h4 style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="text-center text-xl font-semibold text-kura opacity-0">
          <span>
            kontakt:{' '}
            <a href="mailto://contact@kuramatura.eu" target="_blank" rel="noreferrer">
              contact@kuramatura.eu
            </a>
          </span>
        </h4>
        <h4 style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="text-center text-xl font-semibold text-kura opacity-0 mb-4">
          <span>
            súkromie:{' '}
            <a href="/legal/privacy-policy.txt" target="_blank" rel="noreferrer">
              pravidlá ochrany súkromia
            </a>
          </span>
        </h4>
        <a href="https://donate.stripe.com/3cscQGc7f3NX5DW288" target="_blank" rel="noreferrer" className="button-primary bg-kura text-yellow-300 mt-4">
          Podporiť
        </a>
      </div>
      <div className="flex flex-col gap-2 items-center justify-center mt-2 w-full">
        <LogoText className="w-32 min-w-8 shrink-0 mb-px" />
        <h4
          style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }}
          className="text-center text-[0.6125rem] font-bold text-kura opacity-0 mb-2 leading-[170%]"
        >
          copyright 2025 <br />
          <a href="https://repka.dev/" className="text-sm font-bold" target="_blank" rel="noreferrer">
            repka.dev
          </a>
        </h4>
        {/*<div className="flex gap-2 items-center justify-evenly w-full grow text-sm">
          <h4 style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="text-center font-semibold text-kura opacity-0">
            <span>
              dizajn: Aneta Potaś{' '}
              <a href="https://instagram.com/anetapotas" target="_blank" rel="noreferrer">
                (@anetapotas)
              </a>
            </span>
          </h4>
          <h4 style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="text-center font-semibold text-kura opacity-0">
            <span>
              ostatné: Michal Repka{' '}
              <a href="https://instagram.com/michal__repka" target="_blank" rel="noreferrer">
                (@michal__repka)
              </a>
            </span>
          </h4>
</div>*/}
      </div>
    </div>
  )
}

export default AboutUsPage
